import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// components
import Page from "../../components/Page";
import { LoginForm } from "../../components/authentication/login";
import { Icon } from "@iconify/react";

const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: "0px",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  margin: "auto",
  display: "flex",
  height: "100vh",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  backgroundImage: "url(/static/auth-bg.jpeg)",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("md")]: {
    backgroundImage: "none",
  },
}));

// ----------------------------------------------------------------------
const TITLES = [
  "Get Started",
  "Confirm your number",
  "More Options",
  "Finish signing up",
];
export default function Login() {
  const [steps, setSteps] = useState(0);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  return (
    <RootStyle title="Login to Dashboard">
      <ContentStyle>
        {!isMobile && (
          <Stack
            justifyContent="space-around"
            alignItems="center"
            sx={{
              backgroundImage: "url(/static/login-bg.svg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              flexGrow: 1,
              flexShrink: 1,
              height: 1,
              width: "50%",
              maxWidth: "50%",
              p: 2,
              px: 10,
            }}
            spacing={3}
          >
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "common.white",
                  fontSize: "4rem",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Welcome to Afritas
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "common.white",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Home & Properties
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "common.white",
                fontSize: "1.1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              We specializes in real estate services, offering a diverse range
              of residential and commercial properties for sale and rent.{" "}
            </Typography>
            <Box
              sx={{
                boxShadow: "0px 0px 4px 0px #64DC3B",
                bgcolor: "rgba(109, 216, 72, 0.68)",
                borderRadius: 2,
                p: 4,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "common.white",
                  fontWeight: 500,
                  fontSize: "1.1rem",
                  textAlign: "center",
                }}
              >
                Our expertise includes property management, investment
                consultation, and personalized client support to meet various
                real estate needs.
              </Typography>
            </Box>
          </Stack>
        )}
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          sx={{
            flexGrow: 1,
            flexShrink: 0,
            width: { md: "50%", xs: 1 },
            maxWidth: { md: "50%", xs: 1 },
            height: 1,
            p: 5,
            px: { md: 8, xs: 0 },
            pb: { md: 5, xs: 3 },
            overflow: "scroll",
          }}
          spacing={4}
        >
          {isMobile && (
            <Box sx={{ width: 1, display: "flex", alignItems: "start", px: 2 }}>
              <IconButton
                sx={{ bgcolor: "rgba(23, 171, 70, 0.67)" }}
                onClick={() =>
                  (window.location.href = "https://useafritas.com")
                }
              >
                <Icon
                  icon="ep:arrow-left-bold"
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                />
              </IconButton>
            </Box>
          )}
          <Stack
            direction={{ md: "row", xs: "row-reverse" }}
            sx={{ width: 1 }}
            alignItems="center"
            justifyContent={{
              md: steps === 0 ? "space-between" : "start",
              xs: "center",
            }}
          >
            {!isMobile && steps !== 0 && (
              <IconButton
                size="large"
                onClick={() => setSteps((step) => step - 1)}
              >
                <KeyboardArrowLeftIcon
                  fontSize="large"
                  sx={{ color: "#403C3C", width: "3rem", height: "3rem" }}
                />
              </IconButton>
            )}
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "2rem",
                fontWeight: 600,
                width: 1,
                textAlign: {
                  md: steps === 0 ? "start" : "center",
                  xs: "center",
                },
                color: { md: "inherit", xs: "rgba(255, 255, 255, 1)" },
                textShadow: {
                  md: "none",
                  xs: "1px 2px 4px rgba(0, 0, 0, 0.18)",
                },
              }}
              gutterBottom
            >
              {TITLES[steps]}
            </Typography>
            {!isMobile && steps === 0 && (
              <IconButton
                size="large"
                onClick={() =>
                  (window.location.href = "https://useafritas.com")
                }
              >
                <CloseIcon fontSize="large" sx={{ color: "#403C3C" }} />
              </IconButton>
            )}
          </Stack>
          <LoginForm setSteps={setSteps} steps={steps} />
        </Stack>
      </ContentStyle>
    </RootStyle>
  );
}
