import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
// material
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  Alert,
  Link,
  CardHeader,
  Radio,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Label from "../../Label";
// hooks
import useAuth from "../../../hooks/useAuth";
import { COMPLIANCE_STATUS, COMPLIANCE_TYPE } from "../../../utils/apis";

export default function ID() {
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateCompliance } = useAuth();
  const status =
    COMPLIANCE_STATUS[user && user.user ? parseInt(user.user.idStatus, 10) : 0];
  const UpdateUserSchema = Yup.object().shape({
    idType: Yup.string().required(
      "Please select your business type to proceed."
    ),
    idNumber: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idType: (user && user.user && user.user.idType) || "",
      idNumber: (user && user.user && user.user.idNumber) || "",
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateCompliance(values);
        enqueueSnackbar("Your ID details have been saved and will be processed!", {
          variant: "success",
        });
        setSubmitting(false);
      } catch (error) {
        setErrors({ afterSubmit: error.message });
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={{ md: 3, xs: 0 }}>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <Card
              sx={{
                boxShadow: {
                  md: "-1px 1px 11.6px 0px rgba(0, 0, 0, 0.08)",
                  xs: "none",
                },
              }}
            >
              <CardHeader
                title="Let’s add your government ID"
                subheader="We will need you to add at least one official government  ID. This  steps helps us make sure your are real you."
                sx={{
                  mb: 2,
                  "& .MuiCardHeader-title": {
                    fontSize: { md: 24, xs: 18 },
                    fontWeight: 500,
                  },
                  "& .MuiCardHeader-subheader": {
                    fontSize: { md: 13, xs: 12 },
                    fontWeight: 400,
                    color: "rgba(98, 98, 98, 1)",
                  },
                }}
              />
              <Stack
                spacing={{ xs: 2, md: 3 }}
                sx={{ mt: { md: 5, xs: 1 }, p: 3 }}
              >
                {errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}
                <Stack
                  divider={
                    <Divider sx={{ borderColor: "rgba(16, 16, 16, 0.1)" }} />
                  }
                  spacing={1}
                >
                  {COMPLIANCE_TYPE.map((type, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Stack sx={{ width: 1, maxWidth: 500 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 16, fontWeight: 400 }}
                        >
                          {type.label}
                        </Typography>
                        {index === 0 && (
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "rgba(64, 60, 60, 1)",
                            }}
                          >
                            Recommended
                          </Typography>
                        )}
                        {type.value === values.idType && (
                          <FormControlLabel
                            label={type.title}
                            labelPlacement="top"
                            sx={{
                              "& span": {
                                fontSize: 13,
                                fontWeight: 300,
                                color: "rgba(64, 60, 60, 0.7)",
                                pt: 0.5,
                                pl: 2,
                              },
                              mt: 2,
                              alignItems: "start",
                              border: {
                                md: "1.5px solid rgba(0, 0, 0, 1)",
                                xs: "1px solid rgba(0, 0, 0, 0.5)",
                              },
                              borderRadius: 1,
                              mx: 0,
                              width: 1,
                            }}
                            control={
                              <TextField
                                fullWidth
                                name="idNumber"
                                value={values.idNumber || ""}
                                onChange={(e) =>
                                  setFieldValue(
                                    "idNumber",
                                    e.target.value || ""
                                  )
                                }
                                sx={{
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "& .MuiInputAdornment-root p": {
                                    color: {
                                      md: "rgba(64, 60, 60, 1)",
                                      xs: "rgba(95, 95, 95, 1)",
                                    },
                                    fontWeight: 500,
                                    fontSize: 14,
                                  },
                                  "& input": {
                                    py: 0.5,
                                    color: {
                                      md: "rgba(64, 60, 60, 1)",
                                      xs: "rgba(95, 95, 95, 1)",
                                    },
                                  },
                                }}
                              />
                            }
                          />
                        )}
                      </Stack>
                      <Radio
                        name="idType"
                        value={type.value}
                        onChange={() => setFieldValue("idType", type.value)}
                        checked={type.value === values.idType}
                      />
                    </Stack>
                  ))}
                </Stack>
                {values.type &&
                  (parseInt(values.type, 10) === 0 ? (
                    <Stack
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        fullWidth
                        label="Provide your BVN"
                        {...getFieldProps("data")}
                        error={Boolean(touched.data && errors.data)}
                        helperText={touched.data && errors.data}
                      />
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <NotificationsIcon
                          sx={{ width: 20, height: 20, color: "info.main" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          The requested information is required to verify your
                          store and help us protect your store against fraud. We
                          DO NOT share or use your BVN for any other reason.{" "}
                          <Link
                            href="https://selpay.co/privacy"
                            target="_blank"
                          >
                            Learn more.
                          </Link>
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        fullWidth
                        label="RC / BN Number"
                        {...getFieldProps("data")}
                        error={Boolean(touched.data && errors.data)}
                        helperText={touched.data && errors.data}
                      />
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <NotificationsIcon
                          sx={{ width: 20, height: 20, color: "info.main" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          The requested information is required to verify your
                          store and help us protect your Store against fraud. We
                          DO NOT share or use your store's registration Number
                          for any other reason.{" "}
                          <Link
                            href="https://selpay.co/privacy"
                            target="_blank"
                          >
                            Learn more.
                          </Link>
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
              </Stack>
              <Box
                sx={{
                  mt: 3,
                  p: 3,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="start"
                >
                  <Typography variant="subtitle2">
                    Verification Status:
                  </Typography>
                  <Label
                    variant="filled"
                    sx={{ color: "common.white" }}
                    color={status.color}
                  >
                    {status.label}
                  </Label>
                </Stack>
              </Box>
            </Card>
            {user && user.user && parseInt(user.user.idStatus, 10) !== 1 &&
              <Box sx={{ mt: 4 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ px: 4, py: 1.2 }}
                >
                  verify
                </LoadingButton>
              </Box>
            }
          </Grid>
          <Grid item xs={0} md={2} />
        </Grid>
      </Form>
    </FormikProvider>
  );
}
